
@font-face {
  font-family: stepgo-medium;
  src: url("/assets/fonts/stepgo/SF-UI-Text-Medium.ttf") format("TrueType");
}

@font-face {
  font-family: stepgo-regular;
  src: url("/assets/fonts/stepgo/SF-UI-Text-Bold.ttf") format("TrueType");
}

@font-face {
  font-family: stepgo-bold;
  src: url("/assets/fonts/stepgo/SF-UI-Text-Bold.ttf") format("TrueType");
}

@font-face {
  font-family: stepgo-extended;
  src: url("/assets/fonts/stepgo/SF-UI-Text-Light.ttf") format("TrueType");
}

@font-face {
  font-family: stepgo-light;
  src: url("/assets/fonts/stepgo/SF-UI-Text-Light.ttf") format("TrueType");
}