// Primary colors
$lipstic: #01A3A4;
$black:#01A3A4;
$gray:rgb(85,85,85);
$lightGreen:rgb(0,99,99);
$lightGreen1:rgb(161,191,38);
$white:#fff;
$lightYellow1:#F6F7FC;
$gray-23:rgba(251,131,38,0.1);
$gray-99:rgba(0,0,0,0.6);
$yellow-2:#F6F7FC;
$yallow-3:rgb(255,167,19);
// Gradient
//$linear-gradient: linear-gradient(90deg, $blue-23 0%, $blue-41 0%);
