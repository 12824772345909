@font-face {
    font-family: SourceSansPro-Black;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-Black.otf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-BlackIt;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-BlackIt.otf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-Bold;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-Bold.otf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-BoldIt;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-BoldIt.otf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-ExtraLight;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-ExtraLight.otf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-ExtraLightIt;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-ExtraLightIt.otf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-It;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-It.otf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-Light;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-Light.otf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-LightIt;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-LightIt.otf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-Regular;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-Regular.ttf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-Semibold;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-Semibold.otf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-Semibold;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-Semibold.ttf") format("TrueType");
}

@font-face {
    font-family: SourceSansPro-SemiboldIt;
    src: url("/assets/fonts/new-fonts-aviva/SourceSansPro-SemiboldIt.otf") format("TrueType");
}