
@import "../../assets/fonts/fonts.scss";
@import "../../assets/fonts/stepgo/fonts.scss";
@import "../../assets/fonts/aviva/fonts.scss";
@import "../../assets/fonts/new-fonts-aviva/fonts.scss";

  body[color-theme="sbi"] {
    --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
    --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
    --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
    --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
    --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
      Arial, sans-serif;
      $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
    --arrow-color:#01A3A4;
    --goal: #F5F5F5;
    --theme-headerText:#fff;
    --theme-logslot:#01A3A4;
    --profile-color: #01A3A4;
    --graph-background:#fff;
    --close-edit-color: #01A3A4;
    --new-background: #fafafa;
    --new-border-color: #f4f4f4;
    --theme-color-new:#01A3A4;
    //////Green COLOR /////////DEAFUALT////////
     //#01A3A4 --test color 
    --theme-background:#01A3A4; //#00a6a8
    --theme-header:#01A3A4;
    --theme-button:#01A3A4;
    --theme-heading:#01A3A4;
    --theme-activeBtn:#01A3A4;//#01A3A4       
    --theme-plusBtn:#01A3A4; //#01A3A4  water-guage page plus  defualt color--#509bed;          
    --theme-text-color: #01A3A4;//#01A3A4;
    --theme-plusBtnWaterGuage:#01A3A4;  //#509bed;   
    --theme-minusBtnWaterGuage:#01A3A4;  //#509bed;  
    --theme-waterCenterPercent:#01A3A4;  //#509bed;  
    --theme-colorWhite:#FFFFFF;       
    --theme-boxAdd:1px solid #01A3A4; //#01A3A5;
    --theme-color:#01A3A4; //#01A3A5;
    --theme-backgroundLRG: -webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
    --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
    --theme-progressBarWhite:#FFFFFF;
    --theme-colorBGShades:#b9feff; //change this visaly after check the deafulat color;
    --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

 
    ///////////////Font////////////
    --theme-headingFont:1.2rem; //merger wirth headeing font 
    //--theme-CardHeadingFont:1.2rem;
    //--theme-seeAll:0.8rem;//merge with .75
    --theme-counters:0.75rem;
    --theme-cardHeading:1rem;
    --theme-text:0.85rem;
    --theme-textHeading:0.9rem; 
    --theme-titleProfile:#000; 
    --box-shadow:rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    --boxshadow:#00000012 0px 10px 40px;
    --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="birla"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #D83176;
  --arrow-color:#01A3A4;
  --theme-headerText:#fff;
  --theme-logslot:#01A3A4;
  --goal: #F5F5F5;
  --profile-color: #01A3A4;
  --graph-background:#fff;
  --close-edit-color: #01A3A4;
  --new-background: #fafafa;
  --new-border-color: #f4f4f4;
  --theme-color-new:#01A3A4;

  --theme-background:#01A3A4; //#00a6a8
  --theme-header:#01A3A4;
  --theme-button:#01A3A4;
  --theme-heading:#01A3A4;
  --theme-activeBtn:#01A3A4;//#01A3A4       
  --theme-plusBtn:#01A3A4; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #01A3A4;//#01A3A4;
  --theme-plusBtnWaterGuage:#01A3A4;  //#509bed;   
  --theme-minusBtnWaterGuage:#01A3A4;  //#509bed;  
  --theme-waterCenterPercent:#01A3A4;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #01A3A4; //#01A3A5;
  --theme-color:#01A3A4; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#b9feff; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 



  // new vars

  --theme-color:#01A3A4;
  --theme-newHeader:#D5A620;
  --theme-newButton:#01A3A4;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:#FEBF47;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.65rem;  //10px
  --small-font:0.65rem; //12px
  --regular-font:0.75rem; //14px
  --regularM-font:.75rem; //16px
  --medium-font:.8rem; //18px
  --large-font:1.1rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#B8E6F5;
  --normal-color:#393939;
  --cercle-progress:#B8E6F5;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="newmi"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #D83176;
  --arrow-color:#D9B2A3;
  --goal: #F5F5F5;
  --theme-headerText:#fff;
  --theme-logslot:#D83176;
  --profile-color: #D9B2A3;
  --graph-background:#fff;
  --close-edit-color: #D9B2A3;
  --new-background: #fafafa;
  --new-border-color: #f4f4f4;
  --theme-color-new:#D9B2A3;

  --theme-background:#D9B2A3; //#00a6a8
  --theme-header:#D9B2A3;
  --theme-button:#D9B2A3;
  --theme-heading:#D9B2A3;
  --theme-activeBtn:#D9B2A3;//#01A3A4       
  --theme-plusBtn:#D9B2A3; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #D9B2A3;//#01A3A4;
  --theme-plusBtnWaterGuage:#D9B2A3;  //#509bed;   
  --theme-minusBtnWaterGuage:#D9B2A3;  //#509bed;  
  --theme-waterCenterPercent:#D9B2A3;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #D9B2A3; //#01A3A5;
  --theme-color:#D9B2A3; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #D9B2A3 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#b9feff; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 



  // new vars

  --theme-color:#D9B2A3;
  --theme-newHeader:#D9B2A3;
  --theme-newButton:#D9B2A3;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:#D9B2A3;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.875rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:1.125rem; //18px
  --large-font:1.5rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#B8E6F5;
  --normal-color:#393939;
  --cercle-progress:#B8E6F5;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="beato"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #17a2b8;
  --arrow-color:#17a2b8;
  --theme-headerText:#fff;
  --theme-logslot:#17a2b8;
  --goal: #F5F5F5;
  --profile-color: #17a2b8;
  --graph-background:#fff;
  --close-edit-color: #17a2b8;
  --new-background: #fafafa;
  --new-border-color: #f4f4f4;
  --theme-color-new:#17a2b8;
  
  --theme-background:#17a2b8; //#00a6a8
  --theme-header:#17a2b8;
  --theme-button:#17a2b8;
  --theme-heading:#17a2b8;
  --theme-activeBtn:#17a2b8;//#01A3A4       
  --theme-plusBtn:#17a2b8; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #17a2b8;//#01A3A4;
  --theme-plusBtnWaterGuage:#17a2b8;  //#509bed;   
  --theme-minusBtnWaterGuage:#17a2b8;  //#509bed;  
  --theme-waterCenterPercent:#17a2b8;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #17a2b8; //#01A3A5;
  --theme-color:#17a2b8; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #17a2b8 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#F2B70B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  // new vars
  --theme-color:#17a2b8;
  --theme-newHeader:#F2B70B;
  --theme-newButton:#17a2b8;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:#17a2b8;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#F2B70B;
  --normal-color:#393939;
  --cercle-progress:#F2B70B;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="infinite"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #D9B2A3;
  --arrow-color:#D9B2A3;
  --arrow-color:#D9B2A3;
  --theme-headerText:#fff;
  --theme-logslot:#D9B2A3;
  --goal: #D9B2A3;
  --profile-color: #D9B2A3;

  --theme-background:#D9B2A3; //#00a6a8
  --theme-header:#D9B2A3;
  --theme-button:#D9B2A3;
  --theme-heading:#D9B2A3;
  --theme-activeBtn:#D9B2A3;//#01A3A4       
  --theme-plusBtn:#D9B2A3; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #D9B2A3;//#01A3A4;
  --theme-plusBtnWaterGuage:#D9B2A3;  //#509bed;   
  --theme-minusBtnWaterGuage:#D9B2A3;  //#509bed;  
  --theme-waterCenterPercent:#D9B2A3;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #D9B2A3; //#01A3A5;
  --theme-color:#D9B2A3; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #D9B2A3 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#b9feff; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 

  // new vars

  --theme-color:#D9B2A3;
  --theme-newHeader:#D9B2A3;
  --theme-newButton:#D9B2A3;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:#D9B2A3;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.875rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:1.125rem; //18px
  --large-font:1.5rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#B8E6F5;
  --normal-color:#393939;
  --cercle-progress:#B8E6F5;

  --arrow-color:#B8E6F5;
  --goal: #B8E6F5;
  --profile-color: #707070;
  --graph-background:#B8E6F5;
  --close-edit-color: #B8E6F5;
  --new-background: #B8E6F5;
  --new-border-color: #B8E6F5;
  --theme-color-new:#B8E6F5;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="kapiva"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #0B713F;
  --theme-background:#99b949; //#00a6a8
  --theme-header:#99b949;
  --theme-button:#99b949;
  --theme-heading:#99b949;
  --theme-headerText:#fff;
  --theme-logslot:#0B713F;
  --theme-activeBtn:#99b949;//#01A3A4       
  --theme-plusBtn:#99b949; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #99b949;//#01A3A4;
  --theme-plusBtnWaterGuage:#99b949;  //#509bed;   
  --theme-minusBtnWaterGuage:#99b949;  //#509bed;  
  --theme-waterCenterPercent:#99b949;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #99b949; //#01A3A5;
  --theme-color:#99b949; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #99b949 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#F2B70B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS



  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  // new vars
  --theme-color:#99b949;
  --theme-newHeader:#F2B70B;
  --theme-newButton:#99b949;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#F2B70B;
  --normal-color:#393939;
  --cercle-progress:#F2B70B;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  --goal: #99b949ab;
  --profile-color: #99b949;
  --graph-background:#f2f1eb;
  --close-edit-color: #59702f;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#59702f;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="traya"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #0B713F;
  --theme-background:#B7d340; //#00a6a8
  --theme-header:#B7d340;
  --theme-button:#B7d340;
  --theme-heading:#B7d340;
  --theme-activeBtn:#B7d340;//#01A3A4       
  --theme-plusBtn:#B7d340; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #B7d340;//#01A3A4;
  --theme-plusBtnWaterGuage:#B7d340;  //#509bed;   
  --theme-minusBtnWaterGuage:#B7d340;  //#509bed;  
  --theme-waterCenterPercent:#B7d340;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #B7d340; //#01A3A5;
  --theme-color:#B7d340; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #B7d340 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#fff; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

    --theme-headerText:#fff;
    --theme-logslot:#0B713F;
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  // new vars
  --theme-color:#99b949;
  --theme-newHeader:#99b949;
  --theme-newButton:#99b949;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#F2B70B;
  --normal-color:#393939;
  --cercle-progress:#F2B70B;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  --goal: #B7d340;
  --profile-color: #B7d340;
  --graph-background:#f2f1eb;
  --close-edit-color: #59702f;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#59702f;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="traya-female"] {
    --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
    --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
    --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
    --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
    --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
      Arial, sans-serif;
      $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
    --theme-profile-background: #C4695B;
    --theme-background:#E5763E; //#00a6a8
    --theme-header:#E5763E;
    --theme-headerText:#fff;
    --theme-logslot:#C4695B;
    --theme-button:#E5763E;
    --theme-heading:#E5763E;
    --theme-activeBtn:#E5763E;//#01A3A4       
    --theme-plusBtn:#E5763E; //#01A3A4  water-guage page plus  defualt color--#509bed;          
    --theme-text-color: #E5763E;//#01A3A4;
    --theme-plusBtnWaterGuage:#E5763E;  //#509bed;   
    --theme-minusBtnWaterGuage:#E5763E;  //#509bed;  
    --theme-waterCenterPercent:#E5763E;  //#509bed;  
    --theme-colorWhite:#FFFFFF;       
    --theme-boxAdd:1px solid #E5763E; //#01A3A5;
    --theme-color:#E5763E; //#01A3A5;
    --theme-backgroundLRG: -webkit-linear-gradient(top, #E5763E 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
    --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
    --theme-progressBarWhite:#FFFFFF;
    --theme-colorBGShades:#fff; //change this visaly after check the deafulat color;
    --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
    
  
  
    --theme-headingFont:1.2rem; //merger wirth headeing font 
    --theme-counters:0.75rem;
    --theme-cardHeading:1rem;
    --theme-text:0.85rem;
    --theme-textHeading:0.9rem; 
    --theme-titleProfile:#000; 
    // new vars
    --theme-newHeader:#E5763E;
    --theme-newButton:#E5763E;
    --white:#ffffff;
    --black:#333333;
    --black-light:#676767;
    --lightGrey:#FAFAFA;
    --card-border:#F4F4F4;
    --green:green;
    --yellow:yellow;
    --red:red;
    --theme-newFont:'gt-america-standard-regular', sans-serif;
    --ion-font-family: 'gt-america-standard-regular', sans-serif;
    --xsmall-font:0.625rem;  //10px
    --small-font:0.75rem; //12px
    --regular-font:0.871rem; //14px
    --regularM-font:1rem; //16px
    --medium-font:.9rem; //18px
    --large-font:1.2rem;  //24px;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
    --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
    --radio-background:#F2B70B;
    --normal-color:#393939;
    --cercle-progress:#F2B70B;
  
    --arrow-color:#c97b5a;
    --arrow-color:#c97b5a;
    --goal: #E5763E;
    --profile-color: #E5763E;
    --graph-background:#f2f1eb;
    --close-edit-color: #59702f;
    --new-background: #99b9491f;
    --new-border-color: #99b94933;
    --theme-color-new:#59702f;
    --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
  
body[color-theme="fitelo"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #0B713F;
  --theme-background:#ff8643; //#00a6a8
  --theme-header:#ff8643;
  --theme-headerText:#fff;
  --theme-logslot:#0B713F;
  --theme-button:#ff8643;
  --theme-heading:#ff8643;
  --theme-activeBtn:#ff8643;//#01A3A4       
  --theme-plusBtn:#ff8643; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #ff8643;//#01A3A4;
  --theme-plusBtnWaterGuage:#ff8643;  //#509bed;   
  --theme-minusBtnWaterGuage:#ff8643;  //#509bed;  
  --theme-waterCenterPercent:#ff8643;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #ff8643; //#01A3A5;
  --theme-color:#ff8643; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #ff8643 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#F2B70B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
 


  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  // new vars
  --theme-color:#ff8643;
  --theme-newHeader:#F2B70B;
  --theme-newButton:#ff8643;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#F2B70B;
  --normal-color:#393939;
  --cercle-progress:#F2B70B;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  --goal: #99e8d3;
  --profile-color: #ff8643;
  --graph-background:#f2f1eb;
  --close-edit-color: #ff8643;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#ff8643;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="eatfit"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #D83176;
  --theme-background:#D83176; //#00a6a8
  --theme-headerText:#fff;
  --theme-logslot:#D83176;
  --theme-header:#D83176;
  --theme-button:#D83176;
  --theme-heading:#D83176;
  --theme-activeBtn:#D83176;//#01A3A4       
  --theme-plusBtn:#D83176; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #D83176;//#01A3A4;
  --theme-plusBtnWaterGuage:#D83176;  //#509bed;   
  --theme-minusBtnWaterGuage:#D83176;  //#509bed;  
  --theme-waterCenterPercent:#D83176;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #D83176; //#01A3A5;
  --theme-color:#D83176; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #D83176 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  // new vars
  --theme-color:#D83176;
  --theme-newHeader:#0A1F5B;
  --theme-newButton:#D83176;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#0A1F5B;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  --goal: #99e8d3;
  --profile-color: #D83176;
  --graph-background:#f2f1eb;
  --close-edit-color: #D83176;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#D83176;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="alyve"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #8775ed;
  --theme-background:#fe4869; //#00a6a8
  --theme-header:#fe4869;
  --theme-headerText:#fff;
  --theme-logslot:#8775ed;
  --theme-button:#fe4869;
  --theme-heading:#fe4869;
  --theme-activeBtn:#fe4869;//#01A3A4       
  --theme-plusBtn:#fe4869; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #fe4869;//#01A3A4;
  --theme-plusBtnWaterGuage:#fe4869;  //#509bed;   
  --theme-minusBtnWaterGuage:#fe4869;  //#509bed;  
  --theme-waterCenterPercent:#fe4869;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #fe4869; //#01A3A5;
  --theme-color:#fe4869; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #fe4869 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  --theme-color:#fe4869;
  --theme-newHeader:#fe4869;
  --theme-newButton:#fe4869;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#0A1F5B;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  --goal: #99e8d3;
  --profile-color: #fe4869;
  --graph-background:#f2f1eb;
  --close-edit-color: #fe4869;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#fe4869;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="plix"] {
--font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
--font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
--font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
--font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
--font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
  Arial, sans-serif;
  $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #81DC97;
  --theme-background:#81DC97; //#00a6a8
  --theme-header:#81DC97;
  --theme-headerText:#fff;
  --theme-logslot:#81DC97;
  --theme-button:#81DC97;
  --theme-heading:#81DC97;
  --theme-activeBtn:#81DC97;//#01A3A4       
  --theme-plusBtn:#81DC97; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #81DC97;//#01A3A4;
  --theme-plusBtnWaterGuage:#81DC97;  //#509bed;   
  --theme-minusBtnWaterGuage:#81DC97;  //#509bed;  
  --theme-waterCenterPercent:#81DC97;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #81DC97; //#01A3A5;
  --theme-color:#81DC97; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #81DC97 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  --theme-color:#81DC97;
  --theme-newHeader: #81DC97;
  --theme-newButton: #81DC97;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#0A1F5B;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  
  --goal: #99e8d3;
  --profile-color: #81DC97;
  --graph-background:#f2f1eb;
  --close-edit-color: #81DC97;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#81DC97;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="stepgo"] {
--font: "stepgo-regular";
--font-bold: "stepgo-bold";
--font-light: "Hstepgo-light";
--font-medium: "stepgo-medium";
--font-medium-extended: "stepgo-extended";
  $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #0F0F0F;
  --theme-background: #0F0F0F; //#00a6a8
  --theme-header: #0F0F0F;
  --theme-headerText:#fff;
  --theme-logslot:#0F0F0F;
  --theme-button:#0F0F0F;
  --theme-heading:#0F0F0F;
  --theme-activeBtn:#0F0F0F;//#01A3A4       
  --theme-plusBtn:#0F0F0F; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #0F0F0F;//#01A3A4;
  --theme-plusBtnWaterGuage:#0F0F0F;  //#509bed;   
  --theme-minusBtnWaterGuage:#0F0F0F;  //#509bed;  
  --theme-waterCenterPercent:#0F0F0F;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #0F0F0F; //#01A3A5;
  --theme-color:#0F0F0F; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #0F0F0F 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  --theme-color:#0F0F0F;
  --theme-newHeader: #0F0F0F;
  --theme-newButton: #404040;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont: "stepgo-regular";
  --ion-font-family: "stepgo-bold";
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#0A1F5B;
  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  --goal: #404040;
  --profile-color: #0F0F0F;
  --graph-background:#f2f1eb;
  --close-edit-color: #0F0F0F;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#0F0F0F;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="prana"] {
  --theme-profile-background: #347C17;
  --theme-background:#347C17; //#00a6a8
  --theme-header:#347C17;
  --theme-headerText:#fff;
  --theme-logslot:#347C17;
  --theme-button:#347C17;
  --theme-heading:#347C17;
  --theme-activeBtn:#347C17;//#01A3A4       
  --theme-plusBtn:#347C17; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #347C17;//#01A3A4;
  --theme-plusBtnWaterGuage:#347C17;  //#509bed;   
  --theme-minusBtnWaterGuage:#347C17;  //#509bed;  
  --theme-waterCenterPercent:#347C17;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #347C17; //#01A3A5;
  --theme-color:#347C17; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #347C17 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 

  --theme-color:#347C17;
  --theme-newHeader:#CD8B00;
  --theme-newButton:#347C17;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#CD8B00;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  
  --goal: #99e8d3;
  --profile-color: #347C17;
  --graph-background:#f2f1eb;
  --close-edit-color: #347C17;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#347C17;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="trunord"] {
  --theme-profile-background: #203682;
  --theme-background:#203682; //#00a6a8
  --theme-header:#203682;
  --theme-headerText:#fff;
  --theme-logslot:#203682;
  --theme-button:#203682;
  --theme-heading:#203682;
  --theme-activeBtn:#203682;//#01A3A4       
  --theme-plusBtn:#203682; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #203682;//#01A3A4;
  --theme-plusBtnWaterGuage:#203682;  //#509bed;   
  --theme-minusBtnWaterGuage:#203682;  //#509bed;  
  --theme-waterCenterPercent:#203682;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #203682; //#01A3A5;
  --theme-color:#203682; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #203682 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 

  --theme-color:#203682;
  --theme-newHeader:#f2564a;
  --theme-newButton:#203682;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#f2564a;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  
  --goal: #f2564a;
  --profile-color: #203682;
  --graph-background:#f2f1eb;
  --close-edit-color: #203682;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#203682;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="yolo"] {
    --theme-profile-background: #80A542;
    --theme-background:#80A542; //#00a6a8
    --theme-header:#80A542;
    --theme-headerText:#fff;
    --theme-logslot:#80A542;
    --theme-button:#80A542;
    --theme-heading:#80A542;
    --theme-activeBtn:#80A542;//#01A3A4       
    --theme-plusBtn:#80A542; //#01A3A4  water-guage page plus  defualt color--#509bed;          
    --theme-text-color: #80A542;//#01A3A4;
    --theme-plusBtnWaterGuage:#80A542;  //#509bed;   
    --theme-minusBtnWaterGuage:#80A542;  //#509bed;  
    --theme-waterCenterPercent:#80A542;  //#509bed;  
    --theme-colorWhite:#FFFFFF;       
    --theme-boxAdd:1px solid #80A542; //#01A3A5;
    --theme-color:#80A542; //#01A3A5;
    --theme-backgroundLRG: -webkit-linear-gradient(top, #80A542 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
    --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
    --theme-progressBarWhite:#FFFFFF;
    --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
    --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
    --theme-headingFont:1.2rem; //merger wirth headeing font 
    --theme-counters:0.75rem;
    --theme-cardHeading:1rem;
    --theme-text:0.85rem;
    --theme-textHeading:0.9rem; 
    --theme-titleProfile:#000; 
  
    --theme-color:#1B80B9;
    --theme-newHeader:#80A542;
    --theme-newButton:#80A542;
    --white:#ffffff;
    --black:#333333;
    --black-light:#676767;
    --lightGrey:#FAFAFA;
    --card-border:#F4F4F4;
    --green:green;
    --yellow:yellow;
    --red:red;
    --theme-newFont:'gt-america-standard-regular', sans-serif;
    --ion-font-family: 'gt-america-standard-regular', sans-serif;
    --xsmall-font:0.625rem;  //10px
    --small-font:0.75rem; //12px
    --regular-font:0.871rem; //14px
    --regularM-font:1rem; //16px
    --medium-font:.9rem; //18px
    --large-font:1.2rem;  //24px;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
    --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
    --radio-background:#fff;
    --normal-color:#393939;
    --cercle-progress:#f2564a;
    --arrow-color:#1B80B9;
    --goal: #1B80B9;
    --profile-color: #1B80B9;
    --graph-background:#1B80B9;
    --close-edit-color: #80A542;
    --new-background: #1B80B9;
    --new-border-color: #99b94933;
    --theme-color-new:#1B80B9;  
    --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="truworth"] {
  --theme-profile-background: #209797;
  --theme-background:#209797; //#00a6a8
  --theme-header:#209797;
  --theme-headerText:#fff;
  --theme-logslot:#209797;
  --theme-button:#209797;
  --theme-heading:#209797;
  --theme-activeBtn:#209797;//#01A3A4       
  --theme-plusBtn:#209797; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #209797;//#01A3A4;
  --theme-plusBtnWaterGuage:#209797;  //#509bed;   
  --theme-minusBtnWaterGuage:#209797;  //#509bed;  
  --theme-waterCenterPercent:#209797;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #209797; //#01A3A5;
  --theme-color:#209797; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #209797 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 

  --theme-color:#166BC9;
  --theme-newHeader:#209797;
  --theme-newButton:#209797;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#f2564a;
  --arrow-color:#1B80#166BC9-goal: #166BC9;
  --profile-color: #166BC9;
  --graph-background:#166BC9;
  --close-edit-color: #209797;
  --new-background: #166BC9;
  --new-border-color: #99b94933;
  --theme-color-new:#166BC9; 
  --theme-milestone:#fff; 
--theme-milestone2:#000; 
--button-text:#fff;
}

body[color-theme="orthocure"] {
  --theme-profile-background: #3863AF;
  --theme-background:#3863AF; //#00a6a8
  --theme-header:#3863AF;
  --theme-headerText:#fff;
  --theme-logslot:#3863AF;
  --theme-button:#3863AF;
  --theme-heading:#3863AF;
  --theme-activeBtn:#3863AF;//#01A3A4       
  --theme-plusBtn:#3863AF; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #3863AF;//#01A3A4;
  --theme-plusBtnWaterGuage:#3863AF;  //#509bed;   
  --theme-minusBtnWaterGuage:#3863AF;  //#509bed;  
  --theme-waterCenterPercent:#3863AF;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #3863AF; //#01A3A5;
  --theme-color:#3863AF; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #3863AF 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 

  --theme-color:#3863AF;
  --theme-newHeader:#3863AF;
  --theme-newButton:#3863AF;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#bbd3ff;
  --arrow-color:#1B80#3863AF-goal: #3863AF;
  --profile-color: #3863AF;
  --graph-background:#bbd3ff;
  --close-edit-color:#3863AF;
  --new-background: #bbd3ff;
  --new-border-color: #99b94933;
  --theme-color-new:#bbd3ff;  
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="aviva"] {
  --font: "aviva-regular", sans-serif;
  --font-bold: "aviva-bold", sans-serif;
  --font-light: "aviva-light", sans-serif;
  --font-medium: "aviva-medium", sans-serif;
  --font-medium-extended: "aviva-extendedMedium",  sans-serif;
    $sans-font-regular: "aviva-ragular", sans-serif;
  --theme-profile-background: #ffd900;
  --theme-background:#ffd900; //#00a6a8
  --theme-header:#ffd900;
  --theme-headerText:#000;
  --theme-logslot:#004fb6;
  --theme-button:#ffd900;
  --theme-heading:#ffd900;
  --theme-activeBtn:#ffd900;//#01A3A4       
  --theme-plusBtn:#ffd900; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #004fb6;//#01A3A4;
  --theme-plusBtnWaterGuage:#ffd900;  //#509bed;   
  --theme-minusBtnWaterGuage:#ffd900;  //#509bed;  
  --theme-waterCenterPercent:#ffd900;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #ffd900; //#01A3A5;
  --theme-color:#004fb6; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #ffd900 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem;

  --theme-color:#004fb6;
  --theme-newHeader:#ffd900;
  --theme-newButton:#004fb6;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'aviva-regular', sans-serif;
  --ion-font-family: 'aviva-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#ffd900;
  --arrow-color:#ffd900;
  --goal: #ffd900;
  --profile-color: #004fb6;
  --graph-background:#004fb6;
  --close-edit-color:#004fb6;
  --new-background: #fff;
  --new-border-color: #99b94933;
  --theme-color-new:#004fb6;  
  --theme-milestone:#000;
  --theme-milestone2:#000;
  --button-text:#fff;
  --button-text-back:#004fb6;
}


body[color-theme="redcliffe"] {
  --theme-profile-background: #0f3e6a;
  --theme-background:#0f3e6a; //#00a6a8
  --theme-header:#0f3e6a;
  --theme-headerText:#fff;
  --theme-logslot:#0f3e6a; 
  --theme-button:#0f3e6a;
  --theme-heading:#0f3e6a;
  --theme-activeBtn:#0f3e6a;//#01A3A4       
  --theme-plusBtn:#0f3e6a; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #0f3e6a;//#01A3A4;
  --theme-plusBtnWaterGuage:#0f3e6a;  //#509bed;   
  --theme-minusBtnWaterGuage:#0f3e6a;  //#509bed;  
  --theme-waterCenterPercent:#0f3e6a;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #0f3e6a; //#01A3A5;
  --theme-color:#0f3e6a; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #0f3e6a 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
--theme-titleProfile:#000; 
  --theme-color:#e5184e;
  --theme-newHeader:#0f3e6a;
  --theme-newButton:#0f3e6a;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#e5184e;
  --arrow-color:#e5184e;
  --goal: #0f3e6a;
  --profile-color: #e5184e;
  --graph-background:#e5184e;
  --close-edit-color:#e5184e;
  --new-background: #e5184e;
  --new-border-color: #99b94933;
  --theme-color-new:#e5184e;  
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}

body[color-theme="kenko"] {
  --theme-profile-background: #003033;
  --theme-background:#003033; //#00a6a8
  --theme-header:#003033;
  --theme-headerText:#fff;
  --theme-logslot:#003033;
  --theme-button:#003033;
  --theme-heading:#003033;
  --theme-activeBtn:#003033;//#01A3A4       
  --theme-plusBtn:#003033; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #003033;//#01A3A4;
  --theme-plusBtnWaterGuage:#003033;  //#509bed;   
  --theme-minusBtnWaterGuage:#003033;  //#509bed;  
  --theme-waterCenterPercent:#003033;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #003033; //#01A3A5;
  --theme-color:#003033; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #003033 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 

  --theme-color:#003033;
  --theme-newHeader:#003033;
  --theme-newButton:#fbce60;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#fbce60;
  --arrow-color:#003033;
  --goal: #003033;
  --profile-color: #fbce60;
  --graph-background:#fbce60;
  --close-edit-color:#fbce60;
  --new-background: #fbce60;
  --new-border-color: #99b94933;
  --theme-color-new:#fbce60;  
  --theme-milestone:#fff; 
  --theme-milestone2:#000;
  --button-text:#fff;
  --button-text-back:#fbce60;
}

body[color-theme="blubein"] {
  --font: "aviva-regular", sans-serif;
  --font-bold: "aviva-bold", sans-serif;
  --font-light: "aviva-light", sans-serif;
  --font-medium: "aviva-medium", sans-serif;
  --font-medium-extended: "aviva-extendedMedium",  sans-serif;
    $sans-font-regular: "aviva-ragular", sans-serif;
  --theme-profile-background: #176FAC;
  --theme-background:#176FAC; //#00a6a8
  --theme-header:#176FAC;
  --theme-headerText:#fff;
  --theme-logslot:#00CCEE;
  --theme-button:#176FAC;
  --theme-heading:#176FAC;
  --theme-activeBtn:#176FAC;//#01A3A4       
  --theme-plusBtn:#176FAC; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #176FAC;//#01A3A4;
  --theme-plusBtnWaterGuage:#176FAC;  //#509bed;   
  --theme-minusBtnWaterGuage:#176FAC;  //#509bed;  
  --theme-waterCenterPercent:#176FAC;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #176FAC; //#01A3A5;
  --theme-color:#00CCEE; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #176FAC 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#fff;
  --theme-newHeader:#176FAC;
  --theme-newButton:#00CCEE;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#176FAC;
  --arrow-color:#176FAC;
  --goal: #176FAC;
  --profile-color: #00CCEE;
  --graph-background:#00CCEE;
  --close-edit-color:#00CCEE;
  --new-background: #176FAC;
  --new-border-color: #99b94933;
  --theme-color-new:#00CCEE;  
  --theme-milestone:#000;
  --theme-milestone2:#000;
  --button-text:#fff;
  --button-text-back:#00CCEE;
}
body[color-theme="yellowsquash"] {
  --font: "aviva-regular", sans-serif;
  --font-bold: "aviva-bold", sans-serif;
  --font-light: "aviva-light", sans-serif;
  --font-medium: "aviva-medium", sans-serif;
  --font-medium-extended: "aviva-extendedMedium",  sans-serif;
    $sans-font-regular: "aviva-ragular", sans-serif;
  --theme-profile-background: #F9D120;
  --theme-background:#F9D120; //#00a6a8
  --theme-header:#F9D120;
  --theme-headerText:#000;
  --theme-logslot:#000;
  --theme-button:#F9D120;
  --theme-heading:#F9D120;
  --theme-activeBtn:#F9D120;//#01A3A4       
  --theme-plusBtn:#F9D120; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #F9D120;//#01A3A4;
  --theme-plusBtnWaterGuage:#F9D120;  //#509bed;   
  --theme-minusBtnWaterGuage:#F9D120;  //#509bed;  
  --theme-waterCenterPercent:#F9D120;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #F9D120; //#01A3A5;
  --theme-color:#F9D120; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #F9D120 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000;
  --theme-newHeader:#F9D120;
  --theme-newButton:#F9D120;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#F9D120;
  --arrow-color:#F9D120;
  --goal: #F9D120;
  --profile-color: #F9D120;
  --graph-background:#F9D120;
  --close-edit-color:#F9D120;
  --new-background: #F9D120;
  --new-border-color: #99b94933;
  --theme-color-new:#F9D120;  
  --theme-milestone:#000;
  --theme-milestone2:#000;
  --button-text:#000;
}
body[color-theme="elderfly"] {
  --font: "aviva-regular", sans-serif;
  --font-bold: "aviva-bold", sans-serif;
  --font-light: "aviva-light", sans-serif;
  --font-medium: "aviva-medium", sans-serif;
  --font-medium-extended: "aviva-extendedMedium",  sans-serif;
    $sans-font-regular: "aviva-ragular", sans-serif;
  --theme-profile-background: #8E3C84;
  --theme-background:#8E3C84; //#00a6a8
  --theme-header:#8E3C84;
  --theme-headerText:#fff;
  --theme-logslot:#24142D;
  --theme-button:#8E3C84;
  --theme-heading:#8E3C84;
  --theme-activeBtn:#8E3C84;//#01A3A4       
  --theme-plusBtn:#8E3C84; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #8E3C84;//#01A3A4;
  --theme-plusBtnWaterGuage:#8E3C84;  //#509bed;   
  --theme-minusBtnWaterGuage:#8E3C84;  //#509bed;  
  --theme-waterCenterPercent:#8E3C84;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #8E3C84; //#01A3A5;
  --theme-color:#24142D; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #8E3C84 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#fff;
  --theme-newHeader:#8E3C84;
  --theme-newButton:#24142D;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#8E3C84;
  --arrow-color:#8E3C84;
  --goal: #8E3C84;
  --profile-color: #24142D;
  --graph-background:#24142D;
  --close-edit-color:#24142D;
  --new-background: #8E3C84;
  --new-border-color: #99b94933;
  --theme-color-new:#24142D;  
  --theme-milestone:#000;
  --theme-milestone2:#000;
  --button-text:#fff;
  --button-text-back:#24142D;
}
body[color-theme="brilliant"] {
  --font: "aviva-regular", sans-serif;
  --font-bold: "aviva-bold", sans-serif;
  --font-light: "aviva-light", sans-serif;
  --font-medium: "aviva-medium", sans-serif;
  --font-medium-extended: "aviva-extendedMedium",  sans-serif;
    $sans-font-regular: "aviva-ragular", sans-serif;
  --theme-profile-background: #000000;
  --theme-background:#000000; //#00a6a8
  --theme-header:#000000;
  --theme-headerText:#fff;
  --theme-logslot:#A490FF;
  --theme-button:#000000;
  --theme-heading:#000000;
  --theme-activeBtn:#000000;//#01A3A4       
  --theme-plusBtn:#000000; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #000000;//#01A3A4;
  --theme-plusBtnWaterGuage:#000000;  //#509bed;   
  --theme-minusBtnWaterGuage:#000000;  //#509bed;  
  --theme-waterCenterPercent:#000000;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #000000; //#01A3A5;
  --theme-color:#A490FF; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #000000 50%, #F6F7FC 50%)!important; 
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#fff;
  --theme-newHeader:#000000;
  --theme-newButton:#A490FF;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#000000;
  --arrow-color:#000000;
  --goal: #000000;
  --profile-color: #A490FF;
  --graph-background:#A490FF;
  --close-edit-color:#A490FF;
  --new-background: #000000;
  --new-border-color: #99b94933;
  --theme-color-new:#A490FF;  
  --theme-milestone:#000;
  --theme-milestone2:#000;
  --button-text:#fff;
  --button-text-back:#A490FF;
}
body[color-theme="nizcare"] {
  --font: "aviva-regular", sans-serif;
  --font-bold: "aviva-bold", sans-serif;
  --font-light: "aviva-light", sans-serif;
  --font-medium: "aviva-medium", sans-serif;
  --font-medium-extended: "aviva-extendedMedium",  sans-serif;
    $sans-font-regular: "aviva-ragular", sans-serif;
  --theme-profile-background: #1DA69A;
  --theme-background:#1DA69A; //#00a6a8
  --theme-header:#1DA69A;
  --theme-headerText:#fff;
  --theme-logslot:#FFAF34;
  --theme-button:#1DA69A;
  --theme-heading:#1DA69A;
  --theme-activeBtn:#1DA69A;//#01A3A4       
  --theme-plusBtn:#1DA69A; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #1DA69A;//#01A3A4;
  --theme-plusBtnWaterGuage:#1DA69A;  //#509bed;   
  --theme-minusBtnWaterGuage:#1DA69A;  //#509bed;  
  --theme-waterCenterPercent:#1DA69A;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #1DA69A; //#01A3A5;
  --theme-color:#FFAF34; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #1DA69A 50%, #F6F7FC 50%)!important; 
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#fff;
  --theme-newHeader:#1DA69A;
  --theme-newButton:#FFAF34;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#1DA69A;
  --arrow-color:#1DA69A;
  --goal: #1DA69A;
  --profile-color: #FFAF34;
  --graph-background:#FFAF34;
  --close-edit-color:#FFAF34;
  --new-background: #1DA69A;
  --new-border-color: #99b94933;
  --theme-color-new:#FFAF34;  
  --theme-milestone:#000;
  --theme-milestone2:#000;
  --button-text:#fff;
  --button-text-back:#FFAF34;
}
body[color-theme="smartdietplanner"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #8775ed;
  --theme-background:#fe4869; //#00a6a8
  --theme-header:#fe4869;
  --theme-headerText:#fff;
  --theme-logslot:#8775ed;
  --theme-button:#fe4869;
  --theme-heading:#fe4869;
  --theme-activeBtn:#fe4869;//#01A3A4       
  --theme-plusBtn:#fe4869; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #fe4869;//#01A3A4;
  --theme-plusBtnWaterGuage:#fe4869;  //#509bed;   
  --theme-minusBtnWaterGuage:#fe4869;  //#509bed;  
  --theme-waterCenterPercent:#fe4869;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #fe4869; //#01A3A5;
  --theme-color:#fe4869; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #fe4869 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  --theme-color:#fe4869;
  --theme-newHeader:#fe4869;
  --theme-newButton:#fe4869;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#0A1F5B;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  --goal: #99e8d3;
  --profile-color: #fe4869;
  --graph-background:#f2f1eb;
  --close-edit-color: #fe4869;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#fe4869;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="mswastha"] {
  --theme-profile-background: #203e79;
  --theme-background:#203e79; //#00a6a8
  --theme-header:#203e79;
  --theme-headerText:#fff;
  --theme-logslot:#203e79; 
  --theme-button:#203e79;
  --theme-heading:#203e79;
  --theme-activeBtn:#203e79;//#01A3A4       
  --theme-plusBtn:#203e79; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #203e79;//#01A3A4;
  --theme-plusBtnWaterGuage:#203e79;  //#509bed;   
  --theme-minusBtnWaterGuage:#203e79;  //#509bed;  
  --theme-waterCenterPercent:#203e79;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #203e79; //#01A3A5;
  --theme-color:#203e79; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #203e79 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
--theme-titleProfile:#000; 
  --theme-color:#f37325;
  --theme-newHeader:#203e79;
  --theme-newButton:#203e79;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#f37325;
  --arrow-color:#f37325;
  --goal: #203e79;
  --profile-color: #f37325;
  --graph-background:#f37325;
  --close-edit-color:#f37325;
  --new-background: #f37325;
  --new-border-color: #99b94933;
  --theme-color-new:#f37325;  
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="wellbeing"] {
  --font: "wellbeing-regular";
  --font-bold: "wellbeing-bold";
  --font-light: "wellbeing-light";
  --font-medium: "wellbeing-medium";
  --font-medium-extended: "wellbeing-extended";
    $sans-font-regular: "wellbeing-ragular", "Helvetica Neue", Arial, sans-serif;
    --theme-profile-background: #0F0F0F;
    --theme-background: #0F0F0F; //#00a6a8
    --theme-header: #0F0F0F;
    --theme-headerText:#fff;
    --theme-logslot:#0F0F0F;
    --theme-button:#0F0F0F;
    --theme-heading:#0F0F0F;
    --theme-activeBtn:#0F0F0F;//#01A3A4       
    --theme-plusBtn:#0F0F0F; //#01A3A4  water-guage page plus  defualt color--#509bed;          
    --theme-text-color: #0F0F0F;//#01A3A4;
    --theme-plusBtnWaterGuage:#0F0F0F;  //#509bed;   
    --theme-minusBtnWaterGuage:#0F0F0F;  //#509bed;  
    --theme-waterCenterPercent:#0F0F0F;  //#509bed;  
    --theme-colorWhite:#FFFFFF;       
    --theme-boxAdd:1px solid #0F0F0F; //#01A3A5;
    --theme-color:#0F0F0F; //#01A3A5;
    --theme-backgroundLRG: -webkit-linear-gradient(top, #0F0F0F 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
    --theme-font:"wellbeing-regular", "Helvetica Neue", "Arial", "sans-serif";
    --theme-progressBarWhite:#FFFFFF;
    --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
    --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
    --theme-headingFont:1.2rem; //merger wirth headeing font 
    --theme-counters:0.75rem;
    --theme-cardHeading:1rem;
    --theme-text:0.85rem;
    --theme-textHeading:0.9rem; 
    --theme-titleProfile:#000; 
    --theme-color:#0F0F0F;
    --theme-newHeader: #0F0F0F;
    --theme-newButton: #404040;
    --white:#ffffff;
    --black:#333333;
    --black-light:#676767;
    --lightGrey:#FAFAFA;
    --card-border:#F4F4F4;
    --green:green;
    --yellow:yellow;
    --red:red;
    // --theme-newFont: "wellbeing-regular";
    // --ion-font-family: "wellbeing-regular";
    --ion-default-font: "wellbeing-regular";
    --xsmall-font:0.625rem;  //10px
    --small-font:0.75rem; //12px
    --regular-font:0.871rem; //14px
    --regularM-font:1rem; //16px
    --medium-font:.9rem; //18px
    --large-font:1.2rem;  //24px;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
    --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
    --radio-background:#fff;
    --normal-color:#393939;
    --cercle-progress:#0A1F5B;
    --arrow-color:#c97b5a;
    --arrow-color:#c97b5a;
    --goal: #404040;
    --profile-color: #0F0F0F;
    --graph-background:#f2f1eb;
    --close-edit-color: #0F0F0F;
    --new-background: #99b9491f;
    --new-border-color: #99b94933;
    --theme-color-new:#0F0F0F;
    --theme-milestone:#fff; 
  --theme-milestone2:#000;
  --button-text:#fff;
}
body[color-theme="individual1"] {
  --theme-profile-background: #3863AF;
  --theme-background:#3863AF; //#00a6a8
  --theme-header:#3863AF;
  --theme-headerText:#fff;
  --theme-logslot:#3863AF;
  --theme-button:#3863AF;
  --theme-heading:#3863AF;
  --theme-activeBtn:#3863AF;//#01A3A4       
  --theme-plusBtn:#3863AF; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #3863AF;//#01A3A4;
  --theme-plusBtnWaterGuage:#3863AF;  //#509bed;   
  --theme-minusBtnWaterGuage:#3863AF;  //#509bed;  
  --theme-waterCenterPercent:#3863AF;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #3863AF; //#01A3A5;
  --theme-color:#3863AF; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #3863AF 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 

  --theme-color:#3863AF;
  --theme-newHeader:#3863AF;
  --theme-newButton:#3863AF;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#bbd3ff;
  --arrow-color:#1B80#3863AF-goal: #3863AF;
  --profile-color: #3863AF;
  --graph-background:#bbd3ff;
  --close-edit-color:#3863AF;
  --new-background: #bbd3ff;
  --new-border-color: #99b94933;
  --theme-color-new:#bbd3ff;  
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="individual2"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #0B713F;
  --theme-background:#99b949; //#00a6a8
  --theme-header:#99b949;
  --theme-button:#99b949;
  --theme-heading:#99b949;
  --theme-headerText:#fff;
  --theme-logslot:#0B713F;
  --theme-activeBtn:#99b949;//#01A3A4       
  --theme-plusBtn:#99b949; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #99b949;//#01A3A4;
  --theme-plusBtnWaterGuage:#99b949;  //#509bed;   
  --theme-minusBtnWaterGuage:#99b949;  //#509bed;  
  --theme-waterCenterPercent:#99b949;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #99b949; //#01A3A5;
  --theme-color:#99b949; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #99b949 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#F2B70B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  // new vars
  --theme-color:#99b949;
  --theme-newHeader:#F2B70B;
  --theme-newButton:#99b949;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#F2B70B;
  --normal-color:#393939;
  --cercle-progress:#F2B70B;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  --goal: #99b949ab;
  --profile-color: #99b949;
  --graph-background:#f2f1eb;
  --close-edit-color: #59702f;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#59702f;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="individual3"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #8775ed;
  --theme-background:#fe4869; //#00a6a8
  --theme-header:#fe4869;
  --theme-headerText:#fff;
  --theme-logslot:#8775ed;
  --theme-button:#fe4869;
  --theme-heading:#fe4869;
  --theme-activeBtn:#fe4869;//#01A3A4       
  --theme-plusBtn:#fe4869; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #fe4869;//#01A3A4;
  --theme-plusBtnWaterGuage:#fe4869;  //#509bed;   
  --theme-minusBtnWaterGuage:#fe4869;  //#509bed;  
  --theme-waterCenterPercent:#fe4869;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #fe4869; //#01A3A5;
  --theme-color:#fe4869; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #fe4869 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  --theme-color:#fe4869;
  --theme-newHeader:#fe4869;
  --theme-newButton:#fe4869;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#0A1F5B;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  --goal: #99e8d3;
  --profile-color: #fe4869;
  --graph-background:#f2f1eb;
  --close-edit-color: #fe4869;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#fe4869;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="individual4"] {
  --font: "stepgo-regular";
  --font-bold: "stepgo-bold";
  --font-light: "Hstepgo-light";
  --font-medium: "stepgo-medium";
  --font-medium-extended: "stepgo-extended";
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
    --theme-profile-background: #0F0F0F;
    --theme-background: #0F0F0F; //#00a6a8
    --theme-header: #0F0F0F;
    --theme-headerText:#fff;
    --theme-logslot:#0F0F0F;
    --theme-button:#0F0F0F;
    --theme-heading:#0F0F0F;
    --theme-activeBtn:#0F0F0F;//#01A3A4       
    --theme-plusBtn:#0F0F0F; //#01A3A4  water-guage page plus  defualt color--#509bed;          
    --theme-text-color: #0F0F0F;//#01A3A4;
    --theme-plusBtnWaterGuage:#0F0F0F;  //#509bed;   
    --theme-minusBtnWaterGuage:#0F0F0F;  //#509bed;  
    --theme-waterCenterPercent:#0F0F0F;  //#509bed;  
    --theme-colorWhite:#FFFFFF;       
    --theme-boxAdd:1px solid #0F0F0F; //#01A3A5;
    --theme-color:#0F0F0F; //#01A3A5;
    --theme-backgroundLRG: -webkit-linear-gradient(top, #0F0F0F 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
    --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
    --theme-progressBarWhite:#FFFFFF;
    --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
    --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
    --theme-headingFont:1.2rem; //merger wirth headeing font 
    --theme-counters:0.75rem;
    --theme-cardHeading:1rem;
    --theme-text:0.85rem;
    --theme-textHeading:0.9rem; 
    --theme-titleProfile:#000; 
    --theme-color:#0F0F0F;
    --theme-newHeader: #0F0F0F;
    --theme-newButton: #404040;
    --white:#ffffff;
    --black:#333333;
    --black-light:#676767;
    --lightGrey:#FAFAFA;
    --card-border:#F4F4F4;
    --green:green;
    --yellow:yellow;
    --red:red;
    --theme-newFont: "stepgo-regular";
    --ion-font-family: "stepgo-bold";
    --xsmall-font:0.625rem;  //10px
    --small-font:0.75rem; //12px
    --regular-font:0.871rem; //14px
    --regularM-font:1rem; //16px
    --medium-font:.9rem; //18px
    --large-font:1.2rem;  //24px;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
    --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
    --radio-background:#fff;
    --normal-color:#393939;
    --cercle-progress:#0A1F5B;
    --arrow-color:#c97b5a;
    --arrow-color:#c97b5a;
    --goal: #404040;
    --profile-color: #0F0F0F;
    --graph-background:#f2f1eb;
    --close-edit-color: #0F0F0F;
    --new-background: #99b9491f;
    --new-border-color: #99b94933;
    --theme-color-new:#0F0F0F;
    --theme-milestone:#fff; 
  --theme-milestone2:#000;
  --button-text:#fff;
}
body[color-theme="gravid"] {
    --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
    --theme-profile-background: #df7b5b;
  --theme-background:#df7b5b; //#00a6a8
  --theme-header:#df7b5b;
  --theme-headerText:#fff;
  --theme-logslot:#8775ed;
  --theme-button:#df7b5b;
  --theme-heading:#df7b5b;
  --theme-activeBtn:#df7b5b;//#01A3A4       
  --theme-plusBtn:#df7b5b; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #df7b5b;//#01A3A4;
  --theme-plusBtnWaterGuage:#df7b5b;  //#509bed;   
  --theme-minusBtnWaterGuage:#df7b5b;  //#509bed;  
  --theme-waterCenterPercent:#df7b5b;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #df7b5b; //#01A3A5;
  --theme-color:#df7b5b; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #df7b5b 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  --theme-color:#df7b5b;
  --theme-newHeader:#df7b5b;
  --theme-newButton:#df7b5b;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#0A1F5B;

  --arrow-color:#c97b5a;
  --arrow-color:#c97b5a;
  --goal: #99e8d3;
  --profile-color: #c4ded6;
  --graph-background:#f2f1eb;
  --close-edit-color: #df7b5b;
  --new-background: #99b9491f;
  --new-border-color: #99b94933;
  --theme-color-new:#df7b5b;
  --theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="youmatter"] {
      --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
  --font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
  --font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
  --font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
  --font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
    Arial, sans-serif;
    $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #E8988D;
  --theme-background:#E8988D; //#00a6a8
  --theme-headerText:#fff;
  --theme-logslot:#E8988D;
  --theme-header:#E8988D;
  --theme-button:#E8988D;
  --theme-heading:#E8988D;
  --theme-activeBtn:#E8988D;//#01A3A4       
  --theme-plusBtn:#E8988D; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #E8988D;//#01A3A4;
  --theme-plusBtnWaterGuage:#E8988D;  //#509bed;   
  --theme-minusBtnWaterGuage:#E8988D;  //#509bed;  
  --theme-waterCenterPercent:#E8988D;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #E8988D; //#01A3A5;
  --theme-color:#E8988D; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #E8988D 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000; 
  // new vars
  --theme-color:#E8988D;
  --theme-newHeader:#E8988D;
  --theme-newButton:#88a486;
    --white:#ffffff;
    --black:#333333;
    --black-light:#676767;
    --lightGrey:#FAFAFA;
    --card-border:#F4F4F4;
    --green:green;
    --yellow:yellow;
    --red:red;
    --theme-newFont:'gt-america-standard-regular', sans-serif;
    --ion-font-family: 'gt-america-standard-regular', sans-serif;
    --xsmall-font:0.625rem;  //10px
    --small-font:0.75rem; //12px
    --regular-font:0.871rem; //14px
    --regularM-font:1rem; //16px
    --medium-font:.9rem; //18px
    --large-font:1.2rem;  //24px;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
    --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
    --radio-background:#fff;
    --normal-color:#393939;
    --cercle-progress:#0A1F5B;
  
    --arrow-color:#88a486;
    --goal: #88a486;
    --profile-color: #88a486;
    --graph-background:#f2f1eb;
    --close-edit-color: #88a486;
    --new-background: #99b9491f;
    --new-border-color: #99b94933;
    --theme-color-new:#88a486;
    --theme-milestone:#fff; 
  --theme-milestone2:#000;
  --button-text:#fff;
}
body[color-theme="trunord1"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
--font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
--font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
--font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
--font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
  Arial, sans-serif;
  $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #004d8e;
--theme-background:#004d8e; //#00a6a8
--theme-header:#004d8e;
--theme-headerText:#fff;
--theme-logslot:#8775ed;
--theme-button:#004d8e;
--theme-heading:#004d8e;
--theme-activeBtn:#004d8e;//#01A3A4       
--theme-plusBtn:#004d8e; //#01A3A4  water-guage page plus  defualt color--#509bed;          
--theme-text-color: #004d8e;//#01A3A4;
--theme-plusBtnWaterGuage:#004d8e;  //#509bed;   
--theme-minusBtnWaterGuage:#004d8e;  //#509bed;  
--theme-waterCenterPercent:#004d8e;  //#509bed;  
--theme-colorWhite:#FFFFFF;       
--theme-boxAdd:1px solid #004d8e; //#01A3A5;
--theme-color:#004d8e; //#01A3A5;
--theme-backgroundLRG: -webkit-linear-gradient(top, #004d8e 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
--theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
--theme-progressBarWhite:#FFFFFF;
--theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
--theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
--theme-headingFont:1.2rem; //merger wirth headeing font 
--theme-counters:0.75rem;
--theme-cardHeading:1rem;
--theme-text:0.85rem;
--theme-textHeading:0.9rem; 
--theme-titleProfile:#000; 
--theme-color:#004d8e;
--theme-newHeader:#004d8e;
--theme-newButton:#e0c53d;
--white:#ffffff;
--black:#333333;
--black-light:#676767;
--lightGrey:#FAFAFA;
--card-border:#F4F4F4;
--green:green;
--yellow:yellow;
--red:red;
--theme-newFont:'gt-america-standard-regular', sans-serif;
--ion-font-family: 'gt-america-standard-regular', sans-serif;
--xsmall-font:0.625rem;  //10px
--small-font:0.75rem; //12px
--regular-font:0.871rem; //14px
--regularM-font:1rem; //16px
--medium-font:.9rem; //18px
--large-font:1.2rem;  //24px;
--box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
--boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
--btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
--radio-background:#fff;
--normal-color:#393939;
--cercle-progress:#0A1F5B;

--arrow-color:#e0c53d;
--arrow-color:#e0c53d;
--goal: #99e8d3;
--profile-color: #e0c53d;
--graph-background:#f2f1eb;
--close-edit-color: #e0c53d;
--new-background: #99b9491f;
--new-border-color: #99b94933;
--theme-color-new:#004d8e;
--theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="gravid1"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
--font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
--font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
--font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
--font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
  Arial, sans-serif;
  $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #df7b5b;
--theme-background:#df7b5b; //#00a6a8
--theme-header:#df7b5b;
--theme-headerText:#fff;
--theme-logslot:#8775ed;
--theme-button:#df7b5b;
--theme-heading:#df7b5b;
--theme-activeBtn:#df7b5b;//#01A3A4       
--theme-plusBtn:#df7b5b; //#01A3A4  water-guage page plus  defualt color--#509bed;          
--theme-text-color: #df7b5b;//#01A3A4;
--theme-plusBtnWaterGuage:#df7b5b;  //#509bed;   
--theme-minusBtnWaterGuage:#df7b5b;  //#509bed;  
--theme-waterCenterPercent:#df7b5b;  //#509bed;  
--theme-colorWhite:#FFFFFF;       
--theme-boxAdd:1px solid #df7b5b; //#01A3A5;
--theme-color:#df7b5b; //#01A3A5;
--theme-backgroundLRG: -webkit-linear-gradient(top, #df7b5b 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
--theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
--theme-progressBarWhite:#FFFFFF;
--theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
--theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
--theme-headingFont:1.2rem; //merger wirth headeing font 
--theme-counters:0.75rem;
--theme-cardHeading:1rem;
--theme-text:0.85rem;
--theme-textHeading:0.9rem; 
--theme-titleProfile:#000; 
--theme-color:#df7b5b;
--theme-newHeader:#df7b5b;
--theme-newButton:#df7b5b;
--white:#ffffff;
--black:#333333;
--black-light:#676767;
--lightGrey:#FAFAFA;
--card-border:#F4F4F4;
--green:green;
--yellow:yellow;
--red:red;
--theme-newFont:'gt-america-standard-regular', sans-serif;
--ion-font-family: 'gt-america-standard-regular', sans-serif;
--xsmall-font:0.625rem;  //10px
--small-font:0.75rem; //12px
--regular-font:0.871rem; //14px
--regularM-font:1rem; //16px
--medium-font:.9rem; //18px
--large-font:1.2rem;  //24px;
--box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
--boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
--btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
--radio-background:#fff;
--normal-color:#393939;
--cercle-progress:#0A1F5B;

--arrow-color:#c97b5a;
--arrow-color:#c97b5a;
--goal: #99e8d3;
--profile-color: #c4ded6;
--graph-background:#f2f1eb;
--close-edit-color: #df7b5b;
--new-background: #99b9491f;
--new-border-color: #99b94933;
--theme-color-new:#df7b5b;
--theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="gravid2"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
--font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
--font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
--font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
--font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
  Arial, sans-serif;
  $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #df7b5b;
--theme-background:#df7b5b; //#00a6a8
--theme-header:#df7b5b;
--theme-headerText:#fff;
--theme-logslot:#8775ed;
--theme-button:#df7b5b;
--theme-heading:#df7b5b;
--theme-activeBtn:#df7b5b;//#01A3A4       
--theme-plusBtn:#df7b5b; //#01A3A4  water-guage page plus  defualt color--#509bed;          
--theme-text-color: #df7b5b;//#01A3A4;
--theme-plusBtnWaterGuage:#df7b5b;  //#509bed;   
--theme-minusBtnWaterGuage:#df7b5b;  //#509bed;  
--theme-waterCenterPercent:#df7b5b;  //#509bed;  
--theme-colorWhite:#FFFFFF;       
--theme-boxAdd:1px solid #df7b5b; //#01A3A5;
--theme-color:#df7b5b; //#01A3A5;
--theme-backgroundLRG: -webkit-linear-gradient(top, #df7b5b 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
--theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
--theme-progressBarWhite:#FFFFFF;
--theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
--theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
--theme-headingFont:1.2rem; //merger wirth headeing font 
--theme-counters:0.75rem;
--theme-cardHeading:1rem;
--theme-text:0.85rem;
--theme-textHeading:0.9rem; 
--theme-titleProfile:#000; 
--theme-color:#df7b5b;
--theme-newHeader:#df7b5b;
--theme-newButton:#df7b5b;
--white:#ffffff;
--black:#333333;
--black-light:#676767;
--lightGrey:#FAFAFA;
--card-border:#F4F4F4;
--green:green;
--yellow:yellow;
--red:red;
--theme-newFont:'gt-america-standard-regular', sans-serif;
--ion-font-family: 'gt-america-standard-regular', sans-serif;
--xsmall-font:0.625rem;  //10px
--small-font:0.75rem; //12px
--regular-font:0.871rem; //14px
--regularM-font:1rem; //16px
--medium-font:.9rem; //18px
--large-font:1.2rem;  //24px;
--box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
--boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
--btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
--radio-background:#fff;
--normal-color:#393939;
--cercle-progress:#0A1F5B;

--arrow-color:#c97b5a;
--arrow-color:#c97b5a;
--goal: #99e8d3;
--profile-color: #c4ded6;
--graph-background:#f2f1eb;
--close-edit-color: #df7b5b;
--new-background: #99b9491f;
--new-border-color: #99b94933;
--theme-color-new:#df7b5b;
--theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="gravid3"] {
  --font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
--font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
--font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
--font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
--font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
  Arial, sans-serif;
  $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #df7b5b;
--theme-background:#df7b5b; //#00a6a8
--theme-header:#df7b5b;
--theme-headerText:#fff;
--theme-logslot:#8775ed;
--theme-button:#df7b5b;
--theme-heading:#df7b5b;
--theme-activeBtn:#df7b5b;//#01A3A4       
--theme-plusBtn:#df7b5b; //#01A3A4  water-guage page plus  defualt color--#509bed;          
--theme-text-color: #df7b5b;//#01A3A4;
--theme-plusBtnWaterGuage:#df7b5b;  //#509bed;   
--theme-minusBtnWaterGuage:#df7b5b;  //#509bed;  
--theme-waterCenterPercent:#df7b5b;  //#509bed;  
--theme-colorWhite:#FFFFFF;       
--theme-boxAdd:1px solid #df7b5b; //#01A3A5;
--theme-color:#df7b5b; //#01A3A5;
--theme-backgroundLRG: -webkit-linear-gradient(top, #df7b5b 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
--theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
--theme-progressBarWhite:#FFFFFF;
--theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
--theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
--theme-headingFont:1.2rem; //merger wirth headeing font 
--theme-counters:0.75rem;
--theme-cardHeading:1rem;
--theme-text:0.85rem;
--theme-textHeading:0.9rem; 
--theme-titleProfile:#000; 
--theme-color:#df7b5b;
--theme-newHeader:#df7b5b;
--theme-newButton:#df7b5b;
--white:#ffffff;
--black:#333333;
--black-light:#676767;
--lightGrey:#FAFAFA;
--card-border:#F4F4F4;
--green:green;
--yellow:yellow;
--red:red;
--theme-newFont:'gt-america-standard-regular', sans-serif;
--ion-font-family: 'gt-america-standard-regular', sans-serif;
--xsmall-font:0.625rem;  //10px
--small-font:0.75rem; //12px
--regular-font:0.871rem; //14px
--regularM-font:1rem; //16px
--medium-font:.9rem; //18px
--large-font:1.2rem;  //24px;
--box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
--boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
--btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
--radio-background:#fff;
--normal-color:#393939;
--cercle-progress:#0A1F5B;

--arrow-color:#c97b5a;
--arrow-color:#c97b5a;
--goal: #99e8d3;
--profile-color: #c4ded6;
--graph-background:#f2f1eb;
--close-edit-color: #df7b5b;
--new-background: #99b9491f;
--new-border-color: #99b94933;
--theme-color-new:#df7b5b;
--theme-milestone:#fff; 
--theme-milestone2:#000;
--button-text:#fff;
}
body[color-theme="originn"] {
  --font: "aviva-regular", sans-serif;
  --font-bold: "aviva-bold", sans-serif;
  --font-light: "aviva-light", sans-serif;
  --font-medium: "aviva-medium", sans-serif;
  --font-medium-extended: "aviva-extendedMedium",  sans-serif;
    $sans-font-regular: "aviva-ragular", sans-serif;
  --theme-profile-background: #fef5e6;  //#fef5e6
  --theme-background:#fef5e6; //#00a6a8
  --theme-header:#fef5e6;
  --theme-headerText:#612d0f;
  --theme-logslot:#612d0f;
  --theme-button:#f39d52;
  --theme-heading:#fef5e6;
  --theme-activeBtn:#fef5e6;//#01A3A4       
  --theme-plusBtn:#fef5e6; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #612d0f;//#01A3A4;
  --theme-plusBtnWaterGuage:#fef5e6;  //#509bed;   
  --theme-minusBtnWaterGuage:#fef5e6;  //#509bed;  
  --theme-waterCenterPercent:#fef5e6;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #fef5e6; //#01A3A5;
  --theme-color:#612d0f; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #fef5e6 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#000;
  --theme-newHeader:#612d0f;
  --theme-newButton:#f39d52; //#612d0f
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#fef5e6;
  --arrow-color:#fef5e6;
  --goal: #fef5e6;
  --profile-color: #f39d52;
  --graph-background:#612d0f;
  --close-edit-color:#612d0f;
  --new-background: #fef5e6;
  --new-border-color: #99b94933;
  --theme-color-new:#612d0f;  
  --theme-milestone:#fff;
  --theme-milestone2:#000;
  --button-text:#fff;
  --button-text-back:#612d0f;
}
body[color-theme="trunativ"] {
  --font: "aviva-regular", sans-serif;
  --font-bold: "aviva-bold", sans-serif;
  --font-light: "aviva-light", sans-serif;
  --font-medium: "aviva-medium", sans-serif;
  --font-medium-extended: "aviva-extendedMedium",  sans-serif;
    $sans-font-regular: "aviva-ragular", sans-serif;
  --theme-profile-background: #2e3a84;  //#2e3a84
  --theme-background:#2e3a84; //#00a6a8
  --theme-header:#2e3a84;
  --theme-headerText:#fff;
  --theme-logslot:#2e3a84;
  --theme-button:#f39d52;
  --theme-heading:#2e3a84;
  --theme-activeBtn:#2e3a84;//#01A3A4       
  --theme-plusBtn:#2e3a84; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #612d0f;//#01A3A4;
  --theme-plusBtnWaterGuage:#2e3a84;  //#509bed;   
  --theme-minusBtnWaterGuage:#2e3a84;  //#509bed;  
  --theme-waterCenterPercent:#2e3a84;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #2e3a84; //#01A3A5;
  --theme-color:#2e3a84; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #2e3a84 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#CD8B00; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem; 
  --theme-titleProfile:#fff;
  --theme-newHeader:#2e3a84;
  --theme-newButton:#2e3a84; //#612d0f
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:yellow;
  --red:red;
  --theme-newFont:'gt-america-standard-regular', sans-serif;
  --ion-font-family: 'gt-america-standard-regular', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:0.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#fff;
  --normal-color:#393939;
  --cercle-progress:#2e3a84;
  --arrow-color:#2e3a84;
  --goal: #2e3a84;
  --profile-color: #2e3a84;
  --graph-background:#2e3a84;
  --close-edit-color:#2e3a84;
  --new-background: #2e3a84;
  --new-border-color: #99b94933;
  --theme-color-new:#2e3a84;  
  --theme-milestone:#fff;
  --theme-milestone2:#000;
  --button-text:#fff;
  --button-text-back:#2e3a84;
}
body[color-theme="nutrabox"] {
  --font: "Nutrabox-regular";
  --font-bold: "Nutrabox-bold";
  --font-light: "Nutrabox-light";
  // --font-medium: "Nutrabox-medium";
  --font-medium-extended: "Nutrabox-extended";
    $sans-font-regular: "Nutrabox-ragular", "Helvetica Neue", Arial, sans-serif;
    --theme-profile-background: #0F0F0F;
    --theme-background: #0F0F0F; //#00a6a8
    --theme-header: #0F0F0F;
    --theme-headerText:#fff;
    --theme-logslot:#0F0F0F;
    --theme-button:#0F0F0F;
    --theme-heading:#0F0F0F;
    --theme-activeBtn:#0F0F0F;//#01A3A4       
    --theme-plusBtn:#0F0F0F; //#01A3A4  water-guage page plus  defualt color--#509bed;          
    --theme-text-color: #0F0F0F;//#01A3A4;
    --theme-plusBtnWaterGuage:#0F0F0F;  //#509bed;   
    --theme-minusBtnWaterGuage:#0F0F0F;  //#509bed;  
    --theme-waterCenterPercent:#0F0F0F;  //#509bed;  
    --theme-colorWhite:#FFFFFF;       
    --theme-boxAdd:1px solid #0F0F0F; //#01A3A5;
    --theme-color:#0F0F0F; //#01A3A5;
    --theme-backgroundLRG: -webkit-linear-gradient(top, #0F0F0F 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
    --theme-font:"Nutrabox-regular", "Helvetica Neue", "Arial", "sans-serif";
    --theme-progressBarWhite:#FFFFFF;
    --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
    --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
    --theme-headingFont:1.2rem; //merger wirth headeing font 
    --theme-counters:0.75rem;
    --theme-cardHeading:1rem;
    --theme-text:0.85rem;
    --theme-textHeading:0.9rem; 
    --theme-titleProfile:#000; 
    --theme-color:#0F0F0F;
    --theme-newHeader: #0F0F0F;
    --theme-newButton: #404040;
    --white:#ffffff;
    --black:#333333;
    --black-light:#676767;
    --lightGrey:#FAFAFA;
    --card-border:#F4F4F4;
    --green:green;
    --yellow:yellow;
    --red:red;
    // --theme-newFont: "wellbeing-regular";
    // --ion-font-family: "wellbeing-regular";
    --ion-default-font: "Nutrabox-regular";
    --xsmall-font:0.625rem;  //10px
    --small-font:0.75rem; //12px
    --regular-font:0.871rem; //14px
    --regularM-font:1rem; //16px
    --medium-font:.9rem; //18px
    --large-font:1.2rem;  //24px;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
    --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
    --radio-background:#fff;
    --normal-color:#393939;
    --cercle-progress:#0A1F5B;
    --arrow-color:#c97b5a;
    --arrow-color:#c97b5a;
    --goal: #404040;
    --profile-color: #0F0F0F;
    --graph-background:#f2f1eb;
    --close-edit-color: #0F0F0F;
    --new-background: #99b9491f;
    --new-border-color: #99b94933;
    --theme-color-new:#0F0F0F;
    --theme-milestone:#fff; 
  --theme-milestone2:#000;
  --button-text:#fff;
}
body[color-theme="enkeltec"] {
  --font: "Nutrabox-regular";
  --font-bold: "Nutrabox-bold";
  --font-light: "Nutrabox-light";
  --font-medium-extended: "aviva-extendedMedium",  sans-serif;
  $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;
  --theme-profile-background: #0F0F0F;
    --theme-background: #0F0F0F; //#00a6a8
    --theme-header: #0F0F0F;
    --theme-headerText:#fff;
    --theme-logslot:#0F0F0F;
    --theme-button:#0F0F0F;
    --theme-heading:#0F0F0F;
    --theme-activeBtn:#0F0F0F;//#01A3A4       
    --theme-plusBtn:#0F0F0F; //#01A3A4  water-guage page plus  defualt color--#509bed;          
    --theme-text-color: #0F0F0F;//#01A3A4;
    --theme-plusBtnWaterGuage:#0F0F0F;  //#509bed;   
    --theme-minusBtnWaterGuage:#0F0F0F;  //#509bed;  
    --theme-waterCenterPercent:#0F0F0F;  //#509bed;  
    --theme-colorWhite:#FFFFFF;       
    --theme-boxAdd:1px solid #0F0F0F; //#01A3A5;
    --theme-color:#0F0F0F; //#01A3A5;
    --theme-backgroundLRG: -webkit-linear-gradient(top, #0F0F0F 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
    --theme-font:"wellbeing-regular", "Helvetica Neue", "Arial", "sans-serif";
    --theme-progressBarWhite:#FFFFFF;
    --theme-colorBGShades:#0A1F5B; //change this visaly after check the deafulat color;
    --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS
    --theme-headingFont:1.2rem; //merger wirth headeing font 
    --theme-counters:0.75rem;
    --theme-cardHeading:1rem;
    --theme-text:0.85rem;
    --theme-textHeading:0.9rem; 
    --theme-titleProfile:#000; 
    --theme-color:#0F0F0F;
    --theme-newHeader: #0F0F0F;
    --theme-newButton: #404040;
    --white:#ffffff;
    --black:#333333;
    --black-light:#676767;
    --lightGrey:#FAFAFA;
    --card-border:#F4F4F4;
    --green:green;
    --yellow:yellow;
    --red:red;
    // --theme-newFont: "wellbeing-regular";
    // --ion-font-family: "wellbeing-regular";
    --ion-default-font: "wellbeing-regular";
    --xsmall-font:0.625rem;  //10px
    --small-font:0.75rem; //12px
    --regular-font:0.871rem; //14px
    --regularM-font:1rem; //16px
    --medium-font:.9rem; //18px
    --large-font:1.2rem;  //24px;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
    --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
    --radio-background:#fff;
    --normal-color:#393939;
    --cercle-progress:#0A1F5B;
    --arrow-color:#c97b5a;
    --arrow-color:#c97b5a;
    --goal: #404040;
    --profile-color: #0F0F0F;
    --graph-background:#f2f1eb;
    --close-edit-color: #0F0F0F;
    --new-background: #99b9491f;
    --new-border-color: #99b94933;
    --theme-color-new:#0F0F0F;
    --theme-milestone:#fff; 
  --theme-milestone2:#000;
  --button-text:#fff;
}
  
// Fonts
$font-size: 16px;
$font: "gt-america-standard-regular", "Helvetica Neue", Arial;
$font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
$font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
$font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
$font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
  Arial, sans-serif;
  $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;