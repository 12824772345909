
@font-face {
  font-family: gt-america-standard-medium;
  src: url("/assets/fonts/Fredoka-Medium.ttf") format("TrueType");
}

@font-face {
  font-family: gt-america-standard-regular;
  src: url("/assets/fonts/Fredoka-Regular.ttf") format("TrueType");
}

@font-face {
  font-family: gt-america-standard-bold;
  src: url("/assets/fonts/Fredoka-Bold.ttf") format("TrueType");
}

@font-face {
  font-family: gt-america-standard-extendedMedium;
  src: url("/assets/fonts/Fredoka-Light.ttf") format("TrueType");
}

@font-face {
  font-family: gt-america-standard-light;
  src: url("/assets/fonts/Fredoka-Light.ttf") format("TrueType");
}